import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env } from './const';
// import { headers } from '../utils/common';
// import { Role } from '../utils/role';
// import { getRole } from '../utils/common';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import EchartPie from '../dashboard/EchartPie';
import DataTable from "react-data-table-component";
const DamagedInventoryTopFiveProdcuts = (props) => {
    const columns = [
        
        {
            name: "Material ID",
            selector: "materialID",
            sortable: true,
            minWidth: '140px'
        },
        {
            name: "Material Name",
            selector: "materialName",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Damaged QTY",
            selector: "Damage",
            sortable: true,
            minWidth: '150px',
            cell: row => (
                <span className='redcolor' style={{fontWeight: 'bold'}}>{row.Damage} %
                
                </span>
            )
        }    
      ];
      const [data, setData] = useState([
        {
            "siteID": "CST001",
            "siteName": "ABC Site 1",
            "damagedQuantity": '20',
        },
        {
            "siteID": "CST002",
            "siteName": "ABC Site 2",
            "damagedQuantity": '10',
        },
        {
            "siteID": "CST002",
            "siteName": "ABC Site 2",
            "damagedQuantity": '10',
        },
        {
            "siteID": "CST002",
            "siteName": "ABC Site 2",
            "damagedQuantity": '10',
        }
      ]);
      const [pieData, setPieData] = useState([
        {
            "name": "ABC Site 1",
            "value": "100"
        },
        {
            "name": "ABC Site 2",
            "value": "80"
        },
        {
            "name": "ABC Site 3",
            "value": "60"
        },
        {
            "name": "ABC Site 4",
            "value": "110"
        },
        {
            "name": "ABC Site 5",
            "value": "78"
        }
      ])
      const getData = ()=> {
        axios.get(env.produrl + '/misc/topfivematerials')
        .then(res => {
            const data = res.data.list;
            setData(data);
            // const pieData = data.map((item)=>{
            //     item.name = item.SiteName,
            //     item.value = item.Damage
            // })
            const pieData = [];
            data.forEach((item)=>{
                let obj = {
                    name: item.materialID,
                    value: item.Damage
                }
                pieData.push(obj)
            })
            setPieData(pieData);
        }).catch((err) => {
            console.log(err)
        });
    }
useEffect(()=>{
    getData();
}, [])
    return (
        <div className="">
            <div className='row'>
                <div className='col-md-9' style={{fontWeight: 'bold'}}>
                        <h4>Damaged Inventory Top 5 Materials</h4>
                         <DataTable
                            columns={columns}
                            data={data}
                            defaultSortField="batchID"
                           // pagination
                            //selectableRows
                            // selectableRowsComponent={BootyCheckbox}
                            // onSelectedRowsChange = {handleChange}
                            dense
                            selectableRowsHighlight = 'true'
                            compact
                            highlightOnHover = 'true'
                            striped
                        />
                </div>
                <div className='col-md-3'>
                       <EchartPie name="DI Top 5 Sites" data={pieData}></EchartPie>
                </div>
            </div>
        </div>
    );
}
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default DamagedInventoryTopFiveProdcuts;