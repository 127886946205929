import axios from "axios";
import React, {useState, useEffect, useRef} from "react";
import EchartBar from "../dashboard/barchart";
import EchartLine from "../dashboard/echart";
import Datepicker from 'react-datepicker';
import { env } from './const';

const Dashboard = () => {
  const [materialData, setMaterialData] = useState([]);
const _materialsData = ()=>{
  axios.get(env.produrl + '/misc/materialmaster')
  .then(res => {
     setMaterialData(res.data.list);
  }).catch((err) => {
      console.log(err)
  });
}
const[uom, setUom] = useState([])
const uomList = ()=>{
  axios.get(env.produrl + '/misc/uomlist')
  .then(res => {
     setUom(res.data.list);
  }).catch((err) => {
      console.log(err)
  });
}

  useEffect(()=>{
    _materialsData();
    uomList();
  }, [])
  return (
    <div className="dashboard">
       <div className="row">
          <div className="col-md-12" style={{marginTop: 5, marginBottom: 15}}>
                      <div className="row">
                      <div className="col-md-1" style={{textAlign: 'left', marginTop: 5}}><strong>Filter By: </strong></div>
                      </div>
                      <div className="row">
                        
                        <div className="col-md-2">
                            <select class="form-control form-control-sm" name="siteID"><option value="">-Select Site-</option><option>Eureka Park</option><option>Serein</option><option>Rio De Goa</option><option>Myst</option><option>La Vida</option></select>
                        </div>
                        <div className='col-md-2'>
                              <div className="form-group">
                                 
                                  <select class="form-control form-control-sm" name="farmer"
                                   
                                  >
                                      <option value="">-Select Material-</option>
                                      {materialData.map(item => (
                                          <option
                                              key={item.materialId}
                                              value={item.materialId}
                                          >
                                              {item.description}
                                          </option>
                                      ))}
                                  </select>
                                  
                              </div>
                        </div>
                        <div className='col-md-2'>
                              <div className="form-group">
                                  <select class="form-control form-control-sm" name="uom"
                                    
                                  >
                                      <option value="">-Select UOM-</option>
                                      {uom.map(item => (
                                          <option
                                              key={item.uomValue}
                                              value={item.uomValue}
                                          >
                                              {item.uomValue}
                                          </option>
                                      ))}
                                  </select>
                                 
                              </div>
                          </div>
                        <div className="col-md-2">
                            <Datepicker 
                                //selected={selectedDate} 
                                //onChange = {dateSelector}
                                //showTimeSelect
                                //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                placeholderText = "Select From Date" 
                                maxDate={new Date()}
                                //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                dateFormat="dd-MMM-yyyy"
                                name="closeDate"
                                className = "form-control form-control-sm"
                                >
                            </Datepicker>
                        </div>
                        <div className="col-md-2">
                            <Datepicker 
                                //selected={selectedDate} 
                                //onChange = {dateSelector}
                                //showTimeSelect
                                //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                placeholderText = "Select To Date" 
                                maxDate={new Date()}
                                //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                dateFormat="dd-MMM-yyyy"
                                name="closeDate"
                                className = "form-control form-control-sm"
                                >
                            </Datepicker>
                        </div>
                        <div className="col-md-2">
                          <button  style={{marginTop: 0}} className="btn btn-sm promary-btn loggedasButton">Submit</button>
                        </div>
                      </div>
                 
          </div>
       </div>
      <div className="row">
          <div className="col-md-12">
            <div className="card">
               <div className="card-body">
                  <div className="row widgetRow" style={{marginBottom: 15, borderBottom: '1px solid #ccc'}}>
                    <div className="col-md-4">
                       <div className="row">
                          <div className="col-md-3">
                                <div className="iconHolder" style={{background: '#3382f5'}}>
                                    <img src="/dist/img/plan.png" width='50'></img>
                                </div>
                            </div>
                            <div className="col-md-9 borderRight">
                              <h3>Planned (vs) Shortage</h3>
                              <div className="widgetText">Number of Sites: &nbsp;
                                  <span className="numbers">
                                  <span className='redcolor'>6</span>/25</span>
                              </div>
                              <div className="widgetText">Material Type: &nbsp;
                                <span className="numbers redcolor">10</span>
                              </div>
                            </div>
                       </div>
                    </div>
                    <div className="col-md-4">
                         <div className="row">
                          <div className="col-md-3">
                                  <div className="iconHolder" style={{background: '#f6a200'}}>
                                    <img src="/dist/img/alarm.png" width='50'></img>
                                  </div>
                              </div>
                              <div className="col-md-9 borderRight">
                                <h3>Nearinng Out of Stock</h3>
                                  <div className="widgetText">Number of Sites: &nbsp;
                                      <span className="numbers">
                                      <span className='redcolor'>7</span>/25</span>
                                  </div>
                                  <div className="widgetText">Material Type: &nbsp;
                                    <span className="numbers redcolor">12</span>
                                  </div>
                            </div>
                         </div>
                    </div>
                    <div className="col-md-4">
                         <div className="row">
                          <div className="col-md-3">
                                  <div className="iconHolder" style={{background: '#f53062'}}>
                                    <img src="/dist/img/out-of-stock.png" width='50'></img>
                                  </div>
                              </div>
                              <div className="col-md-9">
                                <h3>Out of Stock</h3>
                                <div className="widgetText">Number of Sites: &nbsp;
                                    <span className="numbers">
                                    <span className='redcolor'>2</span>/25</span>
                                </div>
                                <div className="widgetText">Material Type: &nbsp;
                                  <span className="numbers redcolor">5</span>
                                </div>
                            </div>
                         </div>
                    </div>
                  </div>
                  <div className="row widgetRow">
                    <div className="col-md-4">
                       <div className="row">
                          <div className="col-md-3">
                                <div className="iconHolder" style={{background: '#009ec3'}}>
                                <img src="/dist/img/in-stock.png" width='50'></img>
                                </div>
                            </div>
                            <div className="col-md-9 borderRight">
                              <h3>Excess Inventory</h3>
                                <div className="widgetText">Number of Sites: &nbsp;
                                    <span className="numbers">
                                    <span className='redcolor'>3</span>/25</span>
                                </div>
                                <div className="widgetText">Material Type: &nbsp;
                                  <span className="numbers redcolor">4</span>
                                </div>
                            </div>
                       </div>
                    </div>
                    <div className="col-md-4">
                         <div className="row">
                          <div className="col-md-3">
                                  <div className="iconHolder">
                                  <img src="/dist/img/inventory-management.png" width='50'></img>
                                  </div>
                              </div>
                              <div className="col-md-9 borderRight">
                                <h3>Avg. Holding Days</h3>
                                <div className="widgetText" style={{ fontSize: 20}}>
                                  22 Days
                                </div>
                            </div>
                         </div>
                    </div>
                    <div className="col-md-4">
                         <div className="row">
                          <div className="col-md-3">
                                  <div className="iconHolder" style={{background: '#cb2100'}}>
                                  <img src="/dist/img/damaged-package.png" width='50'></img>
                                  </div>
                              </div>
                              <div className="col-md-9">
                                <h3>Avg. Damage Percentage</h3>
                                <div className="widgetText redcolor" style={{ fontSize: 20}}>
                                  5%
                                </div>
                            </div>
                         </div>
                    </div>
                  </div>
               </div>
            </div>
          </div>
         
       </div>
      
       <div className="row">
             <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                              <h3>Inventory Analysis</h3>
                              <EchartBar></EchartBar>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                            <h3>Inventory Holding Days</h3>
                            <EchartLine></EchartLine>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
       </div>
     </div>
  );
};
export default Dashboard;
