import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env } from './const';
// import { headers } from '../utils/common';
// import { Role } from '../utils/role';
// import { getRole } from '../utils/common';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import DataTable from "react-data-table-component";
import DamagedTopFiveSites from './DamagesTopFiveSites';
import DamagedInventoryTopFiveProdcuts from './DamagedInventoryTopFiveProdcuts';
import DamagedInventoryTopFiveSuppliers from './DamagedInventoryTopFiveSuppliers';
const DamagedInventory = (props) => {
    const columns = [
        {
            name: "Material ID",
            selector: "materialID",
            sortable: true,
            minWidth: '100px'
        },
        {
            name: "Material Name",
            selector: "materialName",
            sortable: true,
            minWidth: '120px'
        },
        {
            name: "Site ID",
            selector: "siteID",
            sortable: true,
            minWidth: '140px'
        },
        {
            name: "Site Name",
            selector: "siteName",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Damaged Quantity",
            selector: "DamagedQTY",
            sortable: true,
            minWidth: '150px',
            cell: row => (
                <span className='redcolor' style={{fontWeight: 'bold'}}>{row.DamagedQTY}
                &nbsp; <i class="fa fa-info-circle" style={{color: '#333', fontSize: 14, cursor: 'pointer'}} ></i>
                </span>
            )
        },
        {
            name: "Damaged QTY",
            selector: "Damaged",
            sortable: true,
            minWidth: '150px',
            cell: row => (
                <span className='redcolor' style={{fontWeight: 'bold'}}>{row.Damaged} %</span>
            )
        },      
      ];
      const [damagedData, setDamagedData] = useState([]);
    const getData = ()=> {
        axios.get(env.produrl + '/misc/damagedinventory')
        .then(res => {
            const data = res.data.list;
            setDamagedData(data);   
        }).catch((err) => {
            console.log(err)
        });
    }
    useEffect(()=>{
        getData();
    }, [])
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Damaged Inventory</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <div className=''>
                        <Tabs activeHeaderStyle={{background:'transparent'}}>
                            <Tab label="Snapshot">
                                <div className = "topSites">
                                    <DamagedTopFiveSites></DamagedTopFiveSites>
                                </div>
                                <div className = "topSites">
                                    <DamagedInventoryTopFiveProdcuts></DamagedInventoryTopFiveProdcuts>
                                </div>
                                <div>
                                    <DamagedInventoryTopFiveSuppliers></DamagedInventoryTopFiveSuppliers>
                                </div>
                            </Tab>
                            <Tab label="Damaged Inventory">
                                <DataTable
                                    columns={columns}
                                    data={damagedData}
                                    defaultSortField="batchID"
                                    pagination
                                    //selectableRows
                                    // selectableRowsComponent={BootyCheckbox}
                                    // onSelectedRowsChange = {handleChange}
                                    dense
                                    selectableRowsHighlight = 'true'
                                    compact
                                    highlightOnHover = 'true'
                                    striped
                                />
                            </Tab>
                            {/* <Tab label="Top 5 Materials"></Tab>
                            <Tab label="Top 5 Suppliers"></Tab> */}
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default DamagedInventory;