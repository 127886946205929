import React  from 'react';
import ReactEcharts  from 'echarts-for-react';
const EchartBar = (props)=>   {
    
    let options = {
        // color: ['#333', '#CB2100', '#009EC3'],
        //color:[props.color],
        tooltip: {
            trigger: 'axis',
            axisPointer: {            
                type: 'shadow' 
            }
        },
        legend: {
            data: ['Inward', 'On Hand', 'Damaged'],
            orient: 'horizontal', // This is optional as 'horizontal' is the default value
            bottom: '0%'
        },
        grid: {
            
                left: 40,
                top: 10,
                right: 35,
                bottom: 60,
                containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: ['Eureka Park', 'Serein', 'Rio De Goa', 'Myst', 'La Vida'],
                axisTick: {
                    alignWithLabel: true
                },
                name: 'Sites',
                nameLocation: 'middle',
                nameGap: 30
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Inventory',
                nameLocation: 'middle',
                nameGap: 50
            }
        ],
        series: [
           
                {
                name: 'Inward',
                type: 'bar',
                stack: 'total',
                label: {
                  show: true
                },
                emphasis: {
                  focus: 'series'
                },
                data: [800, 820, 795, 810, 835, 815, 820]
          },
          {
            name: 'On Hand',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [400, 410, 405, 400, 420, 415, 390]
         },
         {
            name: 'Damaged',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [102, 102, 101, 134, 190, 130, 120]
         },
            
        ], 
    };
    return(
        <div className="Temp">
            {/* {props.data.xAxis.length>0 ? */}
            <ReactEcharts
                option={options}
                style={{ height: "300px", width: "100%" }}
                opts={{ renderer: "svg" }}
            /> 
        </div>
    );
}
export default EchartBar;