import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env } from './const';
// import { headers } from '../utils/common';
// import { Role } from '../utils/role';
// import { getRole } from '../utils/common';
import DataTable from "react-data-table-component";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import PendingApproval from './PendingApprovals';
import ApprovedGoods from './AproovedGoodsIssues';
import RejectedGoods from './RejectedGoodsIssues';
const GoodsIssuance = (props) => {
    const columns = [
        {
            name: "Site ID",
            selector: "siteID",
            sortable: true,
            minWidth: '80px'
        },
        {
          name: "Material ID",
          selector: "materialID",
          sortable: true,
          minWidth: '80px'
        },
        {
            name: "Material Name",
            selector: "materailName",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "UOM",
            selector: "uom",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "Available QTY",
            selector: "closingstock",
            sortable: true,
            minWidth: '120px'
        },
        {
            name: "Blocked QTY",
            selector: "blockedQuantity",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: 'Quantity',
            cell: row => (
              <input
                style={{width:100}}
                type="number"
                value={row.input}
                disabled = {row.closingstock==0? true: false}
                onBlur={(e) => handleInputChange(row.materialID, row.siteID, e.target.value, row.closingstock,  row.blockedQuantity)}
              />
            )
          },       
      ];
      const { register, handleSubmit, watch, formState: { errors } } = useForm({
            mode: 'onChange'
        });

    const [items, setItems] = useState([]);
    const [data, setData] = useState([]);
    const handleInputChange = (id, siteID, value,closingstock, blockedQuantity) => {
        
        if(value=='' || value == 0 ) {
            return;
        }
       
        const updatStatus = false;
        const updatedSet = items.forEach((item)=>{
            if(item.materialID == id && item.siteID == siteID && item.qty != undefined) {
                item.value = value;
                updatStatus = true;
                if(value > closingstock) {
                    item.error = true
                } else {
                    item.error = false
                }
            } 
        })
        if(updatStatus) {
            setItems(updatedSet);
            return;
        } else {
            let error = false;
            if(parseInt(value) > (parseInt(closingstock)-parseInt(blockedQuantity))) {
                error = true
            }
            const newItem = { materialID: id, qty: value, siteID: siteID, error};
            setItems(prevItems => [...prevItems, newItem]);
        }
    };
    const [show, setShow] = useState(false);
    
    const issueGoods = ()=>{
       //alert(JSON.stringify(items))
        
        if(items.length == 0) {
            alert('Please add quantities and proceed');
            return;
        }
        let errors = false;
        items.forEach((item)=>{
            if(item.error) {
                alert('Please enter value less than or equal to available stock for the Material: '+item.materialID);
                errors = true
            } 
        })
        
        if(errors === false) {
            setShow(true)
            siteblocks();
            //alert(JSON.stringify(items));
            //Invoke Popup
        } 
    }
   const inventoryList = (siteID)=>{
        axios.get(env.produrl + '/inventory/list/sitewise/'+siteID)
        .then(res => {
           setData(res.data.list);
        }).catch((err) => {
            console.log(err)
        });
    }
    const[blocks, setBlocks] = useState([]);
    const siteblocks = ()=>{
        axios.get(env.produrl + '/misc/blocksList/'+selectedSite)
        .then(res => {
           //alert(JSON.stringify(res.data.list));
           setBlocks(res.data.list);
        }).catch((err) => {
            console.log(err)
        });
    }
    const[contractors, setContractors] = useState([]);
    
    const contractorsList = (siteID)=>{
        axios.get(env.produrl + '/misc/contractorsList/'+siteID)
        .then(res => {
            
           // console.log(res.data.list);
           setContractors(res.data.list);
        }).catch((err) => {
            console.log(err)
        });
    }
    const [siteData, setSiteData] = useState([]);
    const sitesList = ()=>{
        axios.get(env.produrl + '/misc/siteMaster')
        .then(res => {
            const data = res.data.list;
            setSiteData(data);
            if(data.length >0) {
                setSelectedSite(data[0].siteID);
                inventoryList(data[0].siteID);
                contractorsList(data[0].siteID);
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const [selectedSite, setSelectedSite] = useState('')
    const siteChange = (e) => {
      inventoryList(e.target.value);
    }
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const onSubmit = (data, e)=>{
        
        e.preventDefault();
        if(items.length == 0) {
            setShowResults(true);
            setResultMessage({
                error: true, message: 'No Materials found to issue'
            });
            return;
        }
        const dataToPublish = {
            issueInfo: data,
            materialInfo: items
        }
        //alert(JSON.stringify(dataToPublish));
        axios.post(env.produrl + '/goodsissue/add', dataToPublish)
        .then(res => {
            console.log(res.data.message);
            // alert(res.data.message)
            setShowResults(true);
            setResultMessage({
                error: false, message: res.data.message
            });
            e.target.reset();
            setItems([]);
            inventoryList(data[0].siteID);
        }).catch((err) => {
            console.log(err)
            setShowResults(true)
            setResultMessage({
                error: true, message: err.response.data.message
            });
            console.log(err.response.data.message);
        });
    }
    useEffect(()=>{
        sitesList();
    }, [])
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Goods Issue</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{background:'transparent'}}>
                        <Tab label="Create Goods Issue" eventKey="create">
                            <div className="row">
                                <div className="col-md-4">
                                    <form>
                                        <div class="input-group">
                                            {/* <input type="search" className="form-control form-control-sm" 
                                            placeholder="Search by Materia ID / Name" 
                                            //value={searchTerm}
                                            //onKeyUp={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <div class="input-group-append">
                                                <button type="submit" className="btn btn-sm btn-default">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div> */}
                                                <label htmlFor></label>
                                                <select class="form-control form-control-sm" name="sites"
                                                    onChange={(e)=>siteChange(e)}
                                                >
                                                    {siteData.map(item => (
                                                        <option
                                                            key={item.siteID}
                                                            value={item.siteID}
                                                        >
                                                            {item.siteName}
                                                        </option>
                                                    ))}
                                                </select>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-8">
                                    <ul class="controls">
                                        <li><button onClick={()=>issueGoods()} type="button" class="btn btn-block btn-outline-success btn-sm">
                                            Issue Materials</button></li>
                                    </ul>
                                </div>
                            </div>
                            <DataTable
                                columns={columns}
                                data={data}
                                //defaultSortField="templateID"
                                pagination
                                dense
                                selectableRowsHighlight = 'true'
                                compact
                                highlightOnHover = 'true'
                            />
                            </Tab>
                        <Tab label="Pending Approvals" eventKey="pending">
                            <PendingApproval></PendingApproval>
                        </Tab>
                        <Tab label="Approved Goods" eventKey="approved">
                            <ApprovedGoods></ApprovedGoods>
                        </Tab>
                        <Tab label="Rejections" eventKey="rejected">
                            <RejectedGoods></RejectedGoods>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <Modal show={show}>
                <Modal.Title>
                    <h2 className='modalTitle'>Issue Goods to</h2>
                </Modal.Title>
                <form onSubmit={handleSubmit(onSubmit)}> 
                <Modal.Body>
                    
                        <div className='row'>
                                <div className='col-md-12'>
                                    {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                                </div>
                            <div className='col-md-12'>
                                <div className="form-group">
                                    <label htmlFor>Block Name</label>
                                        <select class="form-control form-control-sm" name="farmer"
                                            {...register("block", {
                                                required: "Please select site block",
                                                // onChange: (e) => { onFarmerChange(e) }
                                            })}
                                        >
                                        <option value="">-Select-</option>
                                        {blocks.map(item => (
                                            <option
                                                key={item.blockName}
                                                value={item.blockName}
                                            >
                                                {item.blockName}
                                            </option>
                                        ))}
                                    </select> 
                                    {errors.block && <span className="err-msg">{errors.block.message}</span>}
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="form-group">
                                    <label htmlFor>Contractor</label>
                                        <select class="form-control form-control-sm" name="farmer"
                                            {...register("contractor", {
                                                required: "Please select contractor",
                                                // onChange: (e) => { onFarmerChange(e) }
                                            })}
                                        >
                                        <option value="">-Select-</option>
                                        {contractors.map(item => (
                                            <option
                                                key={item.contractorID}
                                                value={item.contractorID}
                                            >
                                                {item.name}
                                            </option>
                                        ))}
                                    </select> 
                                    {errors.contractor && <span className="err-msg">{errors.contractor.message}</span>}
                                </div>
                            </div>
                        </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="primary btn-sm" onClick={()=>{ setShow(false)}}>
                        Submit
                    </Button> */}
                    <input class="btn btn-primary btn-sm" type='submit' value='Issue'></input>
                    <Button variant="secondary btn-sm" onClick={()=>{ setShow(false)}}>
                        Close
                    </Button>
                </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default GoodsIssuance;