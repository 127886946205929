import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env } from './const';
// import { headers } from '../utils/common';
// import { Role } from '../utils/role';
// import { getRole } from '../utils/common';
import DataTable from "react-data-table-component";
const Outwards = (props) => {
    const columns = [
        {
            name: "Issue ID",
            selector: "gid",
            sortable: true,
            minWidth: '120px',
            cell: row => (
                <span className=''>GID{row.gid}</span>
            )
        },
        {
            name: "Site Name",
            selector: "siteName",
            sortable: true,
            minWidth: '120px'
        },
        {
            name: "block",
            selector: "block",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "Material ID",
            selector: "productID",
            sortable: true,
            maxWidth: '80px'
        },
        {
            name: "Material Name",
            selector: "materialName",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "QTY",
            selector: "quantity",
            sortable: true,
            minWidth: '50px',
            cell: row => (
                <span className='textHiglighter'>{row.quantity}</span>
            )
        },
        {
            name: "Issued By",
            selector: "issuedby",
            sortable: true,
            minWidth: '100px'
        },
        {
            name: "Issued To",
            selector: "issuedto",
            sortable: true,
            minWidth: '100px'
        },
        {
            name: "Contractor",
            selector: "cid",
            sortable: true,
            minWidth: '100px'
        },
      ];
      const [data, setData] = useState([]);
        const outwardList = (siteID)=>{
            axios.get(env.produrl + '/outward/list/')
            .then(res => {
            setData(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
        }
        useEffect(()=>{
            outwardList();
        }, [])

    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">OutWard Goods</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <form action="simple-results.html">
                                <div class="input-group">
                                    <input type="search" className="form-control form-control-md" 
                                    placeholder="Search by Material/Contractor/Site" 
                                    //value={searchTerm}
                                    //onKeyUp={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <div class="input-group-append">
                                        <button type="submit" className="btn btn-md btn-default">
                                            <i class="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> <br></br>
                    <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={data}
                                defaultSortField="batchID"
                                pagination
                                //selectableRows
                                // selectableRowsComponent={BootyCheckbox}
                                // onSelectedRowsChange = {handleChange}
                                dense
                                selectableRowsHighlight = 'true'
                                compact
                                highlightOnHover = 'true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default Outwards;