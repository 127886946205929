import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env } from './const';
// import { headers } from '../utils/common';
// import { Role } from '../utils/role';
// import { getRole } from '../utils/common';
import DataTable from "react-data-table-component"
const Inventory = (props) => {
    const columns = [
        {
            name: "Site ID",
            selector: "siteID",
            sortable: true,
            maxWidth: '60px'
        },
        {
            name: "Mat ID",
            selector: "productID",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "Material Name",
            selector: "materialName",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Opening QTY",
            selector: "openingstock",
            sortable: true,
            minWidth: '150px',
            cell: row => (
                row.openingstock ==0 ?
                    <span className='textHiglighter'  style={{background: 'red'}}>{row.openingstock}</span>:
                    <span className='textHiglighter'>{row.openingstock}</span>
            )
        },
        {
            name: "Inward",
            selector: "inward",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "Inward Returns",
            selector: "inwardreturns",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Outward",
            selector: "outward",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Outward Returns",
            selector: "outwardreturns",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Closing QTY",
            selector: "closingstock",
            sortable: true,
            minWidth: '150px',
            cell: row => (
                row.closingstock ==0 ?
                    <span className='textHiglighter'  style={{background: 'red'}}>{row.closingstock}</span>:
                    <span className='textHiglighter'>{row.closingstock}</span>
            )
        }      
      ];
      const [data, setData] = useState([]);
      const [siteData, setSiteData] = useState([]);

      const sitesList = ()=>{
        axios.get(env.produrl + '/misc/siteMaster')
        .then(res => {
            const data = res.data.list;
            setSiteData(data);
            if(data.length >0) {
                invList(data[0].siteID);
            }
        }).catch((err) => {
            console.log(err)
        });
    }
      
    const invList = (_siteID)=>{
        const date = moment().format('YYYY-MM-DD')
        axios.get( `${env.produrl}/inventory/data/${date}/${_siteID}`)
        .then(res => {
           setData(res.data.list);
        }).catch((err) => {
            console.log(err)
        });
    }
    const siteChange = (e) => {
        invList(e.target.value);
      }
    useEffect(()=>{
        sitesList();
    }, [])
   
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Inventory</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                <div className="row">
                                <div className="col-md-4">
                                    <form>
                                        <div class="input-group">
                                           
                                                <label htmlFor>Filter By Site: &nbsp;</label>
                                                <select class="form-control form-control-sm" name="sites"
                                                    onChange={(e)=>siteChange(e)}
                                                >
                                                    {siteData.map(item => (
                                                        <option
                                                            key={item.siteID}
                                                            value={item.siteID}
                                                        >
                                                            {item.siteName}
                                                        </option>
                                                    ))}
                                                </select>
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="col-md-8">
                                    <ul class="controls">
                                        <li><button onClick={()=>issueGoods()} type="button" class="btn btn-block btn-outline-success btn-sm">
                                            Issue Materials</button></li>
                                    </ul>
                                </div> */}
                            </div><br></br>
                <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={data}
                                defaultSortField="batchID"
                                pagination
                                //selectableRows
                                // selectableRowsComponent={BootyCheckbox}
                                // onSelectedRowsChange = {handleChange}
                                dense
                                selectableRowsHighlight = 'true'
                                compact
                                highlightOnHover = 'true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default Inventory;