import React  from 'react';
import ReactEcharts  from 'echarts-for-react';
const EchartLine = (props)=>   {
    let options =    {
        tooltip : {
            trigger: 'axis'
        },
        calculable : true,
        legend: {
            data: ['Inventory Holding Days'],
            orient: 'horizontal', // This is optional as 'horizontal' is the default value
            bottom: '0%'
        },
        grid: {
            left: 40,
            top: 10,
            right: 35,
            bottom: 80
        },
        xAxis : [
            {
                type: 'category',
                boundaryGap:false,
                name: 'Sites',
                data: ['Eureka Park', 'Serein', 'Rio De Goa', 'Myst', 'La Vida'],
                splitLine: {
                    lineStyle: {
                        color: '#eee'
                    }
                },
                
            }
        ],
        yAxis : [
           {
            type: 'value',
            boundaryGap: [0, '100%'],
            interval: 5,
            name: 'Inventory Holding Days',
            show: true,
            splitLine: {
                lineStyle: {
                    color: '#eee'
                }
            },
           }
        ],
        
        series : [
            {
                name:'Inventory Holding Days',
                type:'line',
                smooth:true,
                data:[25,18,22,20,21],
                markLine: {
                //     data: [{name: 'Maximum temp', yAxis: props.max,
                //     lineStyle: {
                //         color: '#f00'
                //     }
                // }, {name: 'Maximum temp', yAxis: props.min,
                // lineStyle: {
                //     color: '#f00'
                // }}],
               }
            }
        ],
      
    }

    return(
        <div className="Temp">
           
            <ReactEcharts
                option={options}
                style={{ height: "300px", width: "100%" }}
                opts={{ renderer: "svg" }}
            /> 
        </div>
    );
}
export default EchartLine;